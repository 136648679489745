import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { EntityLink } from "~/components/entity-link";

export const AuthorEntityLink = styled(EntityLink)`
  display: block;

  color: #000000;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 18px;
`;

export const Authors = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${AuthorEntityLink} {
    span {
      display: inline-block;

      margin-inline: 2px;
    }
  }
`;
