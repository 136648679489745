import type { PartialPropsOf, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import { ContentActionBar } from "~/components/content/content-action-bar";
import { ContentAuthorImage } from "~/components/content/content-author-image";
import { ContentAuthorLink } from "~/components/content/content-author-link";
import { ContentCoverImage } from "~/components/content/content-cover-image";
import { ContentCoverVideo } from "~/components/content/content-cover-video";
import { ContentHeadline } from "~/components/content/content-headline";
import { ContentLiveTag } from "~/components/content/content-live-tag";
import { ContentPrintHeadline } from "~/components/content/content-print-headline";
import { ContentPublishedDate } from "~/components/content/content-published-date";
import { ContentSections } from "~/components/content/content-sections";
import { ContentSponsorTag } from "~/components/content/content-sponsor-tag";
import { ContentSubHeadline } from "~/components/content/content-sub-headline";
import { ContentSummary } from "~/components/content/content-summary";
import { ContentTopicLink } from "~/components/content/content-topic-link";
import type { Variant as TopicLinkVariant } from "~/components/content/content-topic-link";
import type { SponsorTag } from "~/components/sponsor-tag";
import { VideoDurationOverlay } from "~/components/video/video-duration-overlay";
import type { hooksContentItemProvidersContent$key } from "~/queries/__generated__/hooksContentItemProvidersContent.graphql";

export type DefaultComponentsProviders = {
  actionBar: (props?: PartialPropsOf<typeof ContentActionBar>) => ReactNode;
  authorImage: (props?: PartialPropsOf<typeof ContentAuthorImage>) => ReactNode;
  authorLink: (props?: PartialPropsOf<typeof ContentAuthorLink>) => ReactNode;
  coverImage: (props?: PartialPropsOf<typeof ContentCoverImage>) => ReactNode;
  coverVideo: (props?: PartialPropsOf<typeof ContentCoverVideo>) => ReactNode;
  headline: (props?: PartialPropsOf<typeof ContentHeadline>) => ReactNode;
  liveTag: (props?: PartialPropsOf<typeof ContentLiveTag>) => ReactNode;
  printHeadline: (props?: PartialPropsOf<typeof ContentPrintHeadline>) => ReactNode;
  publishedDate: (props?: PartialPropsOf<typeof ContentPublishedDate>) => ReactNode;
  sections: (props?: PartialPropsOf<typeof ContentSections>) => ReactNode;
  sponsorTag: (
    props?: PartialPropsOf<typeof ContentSponsorTag> & PartialPropsOf<typeof SponsorTag>,
  ) => ReactNode;
  subHeadline: (props?: PartialPropsOf<typeof ContentSubHeadline>) => ReactNode;
  summary: (props?: PartialPropsOf<typeof ContentSummary>) => ReactNode;
  topicLink?: (props?: PartialPropsOf<typeof ContentTopicLink>) => ReactNode;
  video?: {
    durationOverlay: (props?: PartialPropsOf<typeof VideoDurationOverlay>) => ReactNode;
  };
};

export const useContentItemProviders = (
  reference: hooksContentItemProvidersContent$key,
  topicLinkVariant?: TopicLinkVariant,
) => {
  const content = useFragment(
    graphql`
      fragment hooksContentItemProvidersContent on Content
      @argumentDefinitions(
        withActionBar: { type: "Boolean", defaultValue: false }
        withAuthorImage: { type: "Boolean", defaultValue: false }
        withAuthorLink: { type: "Boolean", defaultValue: false }
        withCoverImage: { type: "Boolean", defaultValue: false }
        withCoverImageSize1200x800: { type: "Boolean", defaultValue: false }
        withCoverImageSize1200x1200: { type: "Boolean", defaultValue: false }
        withCoverImageSize1280x720: { type: "Boolean", defaultValue: false }
        withCoverImageSize768x768: { type: "Boolean", defaultValue: false }
        withCoverImageSize540x360: { type: "Boolean", defaultValue: false }
        withCoverImageSize320x240: { type: "Boolean", defaultValue: false }
        withCoverImageSize320x180: { type: "Boolean", defaultValue: false }
        withCoverImageSize80x80: { type: "Boolean", defaultValue: false }
        withCoverImageSize500x500: { type: "Boolean", defaultValue: false }
        withCoverImageSize800x1200: { type: "Boolean", defaultValue: false }
        withCoverVideo: { type: "Boolean", defaultValue: false }
        withHeadline: { type: "Boolean", defaultValue: false }
        withLiveTag: { type: "Boolean", defaultValue: false }
        withPrintHeadline: { type: "Boolean", defaultValue: false }
        withPublishedDate: { type: "Boolean", defaultValue: false }
        withSections: { type: "Boolean", defaultValue: false }
        withSponsorTag: { type: "Boolean", defaultValue: true }
        withSubHeadline: { type: "Boolean", defaultValue: false }
        withSummary: { type: "Boolean", defaultValue: false }
        withTopicLink: { type: "Boolean", defaultValue: false }
        withVideoDurationOverlay: { type: "Boolean", defaultValue: false }
      ) {
        ...contentActionBarContent @include(if: $withActionBar)
        ...contentAuthorImageContent @include(if: $withAuthorImage)
        ...contentAuthorLinkContent @include(if: $withAuthorLink)
        ...contentCoverImageContent
          @arguments(
            withSize1200x800: $withCoverImageSize1200x800
            withSize1200x1200: $withCoverImageSize1200x1200
            withSize1280x720: $withCoverImageSize1280x720
            withSize768x768: $withCoverImageSize768x768
            withSize540x360: $withCoverImageSize540x360
            withSize320x240: $withCoverImageSize320x240
            withSize320x180: $withCoverImageSize320x180
            withSize80x80: $withCoverImageSize80x80
            withSize500x500: $withCoverImageSize500x500
            withSize800x1200: $withCoverImageSize800x1200
          )
          @include(if: $withCoverImage)
        ...contentCoverVideoContent @include(if: $withCoverVideo)
        ...contentHeadlineContent @include(if: $withHeadline)
        ...contentLiveTagContent @include(if: $withLiveTag)
        ...contentPrintHeadlineContent @include(if: $withPrintHeadline)
        ...contentPublishedDateContent @include(if: $withPublishedDate)
        ...contentSectionsContent @include(if: $withSections)
        ...contentSubHeadlineContent @include(if: $withSubHeadline)
        ...contentSummaryContent @include(if: $withSummary)
        ...contentTopicLinkContent @include(if: $withTopicLink)
        ...contentSponsorTagContent @include(if: $withSponsorTag)
        ... on Video {
          __typename
          ...videoDurationOverlayVideo @include(if: $withVideoDurationOverlay)
        }
      }
    `,
    reference,
  );

  const providers: DefaultComponentsProviders = {
    actionBar: props => <ContentActionBar reference={content} {...props} />,
    authorImage: props => <ContentAuthorImage reference={content} {...props} />,
    authorLink: props => <ContentAuthorLink reference={content} {...props} />,
    coverImage: props => (
      <ContentCoverImage
        reference={content}
        responsiveVariants={{
          desktopUp: "size80x80",
          mobileUp: "size80x80",
          tabletUp: "size80x80",
        }}
        {...props}
      />
    ),
    coverVideo: props => <ContentCoverVideo reference={content} {...props} />,
    headline: props => (
      <ContentHeadline reference={content} skipHeadlineSponsorStyle={false} {...props} />
    ),
    liveTag: props => <ContentLiveTag reference={content} {...props} />,
    printHeadline: props => <ContentPrintHeadline reference={content} {...props} />,
    publishedDate: props => <ContentPublishedDate reference={content} {...props} />,
    sections: props => <ContentSections reference={content} {...props} />,
    sponsorTag: props => <ContentSponsorTag reference={content} {...props} />,
    subHeadline: props => <ContentSubHeadline reference={content} {...props} />,
    summary: props => <ContentSummary reference={content} {...props} />,
  };

  if (topicLinkVariant) {
    providers.topicLink = props => (
      <ContentTopicLink reference={content} variant={topicLinkVariant} {...props} />
    );
  }

  if (content.__typename === "Video") {
    providers.video = {
      durationOverlay: props => (
        <VideoDurationOverlay
          reference={content}
          responsiveVariants={{ desktopUp: "medium", mobileUp: "small", tabletUp: "medium" }}
          variant="default"
          {...props}
        />
      ),
    };
  }

  return providers;
};
