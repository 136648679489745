import first from "lodash/first";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { checkIfNewsSectionInFirstIndex } from "~/lib/section";
import type { contentSectionsContent$key } from "~/queries/__generated__/contentSectionsContent.graphql";

import { SectionContainer, SectionLink } from "./styles";

export type Props = {
  className?: string;
  onClick?: (sectionName: string) => void;
  reference: contentSectionsContent$key;
};

export const ContentSections: FunctionComponent<Props> = ({
  className,
  onClick,
  reference: reference_,
}) => {
  const { sections } = useFragment(
    graphql`
      fragment contentSectionsContent on Content {
        sections {
          value {
            ...helpersFilterSection
            ...entityLink
            name
          }
        }
      }
    `,
    reference_,
  );

  const firstSection = useMemo(() => first(sections)?.value ?? [], [sections]);
  const sectionsForDisplay = useMemo(
    () =>
      firstSection
        .slice(-1)
        .filter((section, index) => !checkIfNewsSectionInFirstIndex(section, index)),
    [firstSection],
  );

  const render = () =>
    sectionsForDisplay.map((section, index) =>
      section ? (
        <SectionLink key={index} reference={section} onClick={() => onClick?.(section.name)}>
          <span>{section?.name}</span>
        </SectionLink>
      ) : null,
    );

  if (sectionsForDisplay.length === 0) return null;

  return <SectionContainer className={className}>{render()}</SectionContainer>;
};

ContentSections.displayName = "ContentSections";
