import type { FunctionComponent } from "react";

import { Container, Duration, DurationContainer, Icon } from "./styles";

type Props = {
  className?: string;
  duration: string;
};

export const HomepageVideoDurationOverlay: FunctionComponent<Props> = ({ className, duration }) => (
  <Container className={className}>
    <Icon />
    <DurationContainer>
      <Duration>{duration}</Duration>
    </DurationContainer>
  </Container>
);
