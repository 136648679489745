import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { EntityLink } from "~/components/entity-link";

export const SectionContainer = styled.div``;

export const SectionLink = styled(EntityLink)`
  color: #4585ff;
  font-weight: 700;
  font-size: 13px;
  font-family: ${fontRobotoCondensed};
  text-transform: uppercase;
`;
