/**
 * @generated SignedSource<<a215e45bee633d84b7b4fc2f117c82bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentSponsorTagContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"hooksUseContentSponsorContent">;
  readonly " $fragmentType": "contentSponsorTagContent";
};
export type contentSponsorTagContent$key = {
  readonly " $data"?: contentSponsorTagContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentSponsorTagContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentSponsorTagContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksUseContentSponsorContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "d557bdd695b518334ec246c257785da1";

export default node;
