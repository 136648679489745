import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { DefaultVideoDurationOverlay } from "~/components/video/video-duration-overlay/variants/default";
import type { StyledProps } from "~/components/video/video-duration-overlay/variants/default/styles";
import { HomepageVideoDurationOverlay } from "~/components/video/video-duration-overlay/variants/homepage";
import type { ResponsiveVariants } from "~/lib/styles/responsive";
import type { videoDurationOverlayVideo$key } from "~/queries/__generated__/videoDurationOverlayVideo.graphql";

type Variant = "default" | "homepage";

export type ResponsiveVariantsProps = {
  $responsiveVariants: ResponsiveVariants<Variant>;
};

type Props = {
  className?: string;
  reference: videoDurationOverlayVideo$key;
  variant: Variant;
} & PropsFromStyledProps<StyledProps>;

export const VideoDurationOverlay: FunctionComponent<Props> = ({
  className,
  reference: reference_,
  responsiveVariants,
  variant = "default",
}) => {
  const video = useFragment(
    graphql`
      fragment videoDurationOverlayVideo on Video {
        duration
      }
    `,
    reference_,
  );

  const duration = video?.duration ?? "";

  switch (variant) {
    case "default":
      return (
        <DefaultVideoDurationOverlay
          className={className}
          duration={duration}
          responsiveVariants={responsiveVariants}
        />
      );
    case "homepage":
      return <HomepageVideoDurationOverlay className={className} duration={duration} />;
  }
};

VideoDurationOverlay.displayName = "VideoDurationOverlay";
