import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import type { ResponsiveVariants } from "~/lib/styles/responsive";
import { setResponsiveVariantStyles } from "~/lib/styles/responsive";

import PlayIcon from "./play.svg";

export const Duration = styled.span`
  color: #ffca05;
  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 16px;
`;

export const Icon = styled(PlayIcon)``;

type Variant = "large" | "medium" | "small";
export type StyledProps = {
  $responsiveVariants: ResponsiveVariants<Variant>;
};

export const Container = styled.div<StyledProps>`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;

  display: grid;
  grid:
    "content" 1fr
    / 1fr;

  inline-size: 100%;
  block-size: 100%;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));

  ${Icon} {
    grid-area: content;

    place-self: start end;
  }

  ${Duration} {
    grid-area: content;

    place-self: end start;
  }

  ${props =>
    setResponsiveVariantStyles(props.$responsiveVariants, variant => {
      switch (variant) {
        case "large":
          return css`
            padding: 12px;
            ${Icon} {
              display: unset;

              inline-size: 62px;
              block-size: 62px;
            }
          `;
        case "medium":
          return css`
            padding: 12px;
            ${Icon} {
              display: unset;

              inline-size: 36px;
              block-size: 36px;
            }
          `;
        case "small":
          return css`
            padding: 4px;
            ${Icon} {
              display: none;
            }
          `;
      }
    })}
`;
