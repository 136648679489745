import first from "lodash/first";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { useContentHelper } from "~/components/content/hooks";
import type { contentAuthorLinkContent$key } from "~/queries/__generated__/contentAuthorLinkContent.graphql";

import { AuthorEntityLink, Authors } from "./styles";

type Props = {
  className?: string;
  ignoreAuthorType?: boolean;
  isMultiple?: boolean;
  reference: contentAuthorLinkContent$key;
};

export const ContentAuthorLink: FunctionComponent<Props> = ({
  className,
  ignoreAuthorType,
  isMultiple,
  reference: reference_,
}) => {
  const content = useFragment(
    graphql`
      fragment contentAuthorLinkContent on Content {
        authors {
          entityId
          name
          ...entityLink
        }
        ...hooksContentHelperContent
      }
    `,
    reference_,
  );
  const { isEditorialOpinionType, isScmpOpinionType } = useContentHelper(content);
  if (!(isScmpOpinionType || isEditorialOpinionType) && !ignoreAuthorType) return null;

  const mainAuthor = first(content.authors);
  if (!mainAuthor) return null;
  const authors = content?.authors;

  const multipleAuthors = () =>
    authors?.map((author, index) =>
      author ? (
        <AuthorEntityLink key={author.entityId} reference={author}>
          {author?.name}
          {index + 1 === authors.length ? null : <span>,</span>}
        </AuthorEntityLink>
      ) : null,
    );

  return isMultiple ? (
    <Authors className={className}>{multipleAuthors()}</Authors>
  ) : (
    <AuthorEntityLink className={className} reference={mainAuthor}>
      {mainAuthor.name}
    </AuthorEntityLink>
  );
};
