import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { SponsorTag } from "~/components/sponsor-tag";
import type { contentSponsorTagContent$key } from "~/queries/__generated__/contentSponsorTagContent.graphql";

import { useContentSponsor } from "./hooks";

export type Props = {
  className?: string;
  reference: contentSponsorTagContent$key;
};

export const ContentSponsorTag: FunctionComponent<Props> = ({
  reference: reference_,
  ...props
}) => {
  const content = useFragment(
    graphql`
      fragment contentSponsorTagContent on Content {
        ...hooksUseContentSponsorContent
      }
    `,
    reference_,
  );
  const contentSponsor = useContentSponsor(content);
  if (contentSponsor?.name) {
    return <SponsorTag reference={contentSponsor} {...props} />;
  }
  return null;
};

ContentSponsorTag.displayName = "ContentSponsorTag";
