import { graphql, readInlineData } from "react-relay";

import type { topicCheckSuperTopic$key } from "~/queries/__generated__/topicCheckSuperTopic.graphql";

export function checkIsSuperTopic(topic_: topicCheckSuperTopic$key) {
  const topic = readInlineData(
    graphql`
      fragment topicCheckSuperTopic on Topic @inline {
        types
      }
    `,
    topic_,
  );

  return topic.types?.includes("super");
}
