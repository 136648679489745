import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import PlayIcon from "./play.svg";

export const DurationContainer = styled.div`
  padding: 4px;

  border-radius: 0 4px 0 0;

  background: #ffffff;
`;

export const Duration = styled.span`
  color: #4585ff;
  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 16px;
`;

export const Icon = styled(PlayIcon)`
  margin: 8px;
`;

export const Container = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;

  display: grid;
  grid:
    "content" 1fr
    / 1fr;

  inline-size: 100%;
  block-size: 100%;

  ${Icon} {
    grid-area: content;

    place-self: start end;
  }

  ${DurationContainer} {
    grid-area: content;

    place-self: end start;
  }
`;
