/**
 * @generated SignedSource<<77cfbf50d7fa61c0a1ad40e210327b9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentActionBarContent$data = {
  readonly createdDate: number | null | undefined;
  readonly entityId: string;
  readonly hasVideoContent?: boolean | null | undefined;
  readonly publishedDate: number | null | undefined;
  readonly updatedDate: number | null | undefined;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"articleCommentArticle">;
  readonly " $fragmentType": "contentActionBarContent";
};
export type contentActionBarContent$key = {
  readonly " $data"?: contentActionBarContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentActionBarContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentActionBarContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleCommentArticle"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasVideoContent",
          "storageKey": null
        }
      ],
      "type": "Article",
      "abstractKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "43780892887203f93da214cc8707fe6e";

export default node;
