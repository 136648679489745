import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { TopicLink } from "~/components/topic/topic-link";

export const StyledTopicLink = styled(TopicLink)`
  display: block;

  color: #001246;
  font-weight: 700;
  font-size: 13px;
  font-family: ${fontRobotoCondensed};
  line-height: 16px;
`;
